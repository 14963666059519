import Vue from 'vue'
import VueRouter from 'vue-router'
import { Route } from 'vue-router/types/router' // todo
import View404 from '@/views/View404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login/LoginView.vue'),
  },
  { path: '*', component: View404, }
]

// évite l'erreur NavigationDuplicated
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function(location: any, onComplete?: any, onAbort?: any): any {
  originalPush.call(
    this,
    location,
    onComplete,
    function(error) {
      if (error && (error.name !== 'NavigationDuplicated')) {
        if (onAbort) {
          onAbort(error)
        } else {
          console.error('navigation error', error) // eslint-disable-line no-console
        }
      }
    }
  )
}

const router: VueRouter = new VueRouter(
  {
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  }
)

router.beforeEach((to : Route, from : Route, next : any) => {
  document.title = 'millibase.net'
  if (to.path !== '/') {
    next('/')
  } else {
    next()
  }
})

export default router
