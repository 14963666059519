<template>
  <div class="view-404">
    <h1>La page demandée n'existe pas</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'View404',
  components: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="less">
</style>
